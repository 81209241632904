<template lang="html" src="./quantitySelector.template.vue"></template>

<style lang="scss" src="./quantitySelector.scss"></style>

<script>
export default {
  name: 'QuantitySelector',
  props: {
    part: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      quantityComputed: null,
      minComputed: null,
    };
  },
  created() {
    this.quantityComputed = Number.parseInt(this.quantity, 10);
    this.minComputed = Number.parseInt(this.min, 10);
    /**
     * Set the minimal acceptable value if the quantity is less than the minimal value
     */
    if (this.quantityComputed < this.minComputed) {
      this.quantityComputed = this.minComputed;
    }
  },
  methods: {
    quantityInputValidate() {
      if (
        !Number.isInteger(this.quantityComputed) ||
        this.quantityComputed < this.minComputed
      ) {
        /**
         * Reset to minimal
         */
        this.resetQuantity();
      } else {
        this.quantityUpdated();
      }
    },
    quantityIncrease() {
      this.quantityComputed = this.quantityComputed + 1;
      this.quantityUpdated();
    },
    quantityDecrease() {
      if (this.quantityComputed > this.minComputed) {
        this.quantityComputed = this.quantityComputed - 1;
      }
      this.quantityUpdated();
    },
    resetQuantity() {
      this.quantityComputed = this.minComputed;
      this.quantityUpdated();
    },
    quantityUpdated() {
      this.$emit('quantityUpdated', this.quantityComputed, this.part);
    },
  },
};
</script>
