<template lang="html" src="./pageBasket.template.vue"></template>

<style lang="scss" src="./pageBasket.scss"></style>

<script>
import {
  ApiErrorParser,
  DownloadBrandPartImage,
  FormAddress,
  EventBus,
  ObjectCompare,
  Steppers
} from '@cloudmanufacturingtechnologies/portal-components';
import QuantitySelector from '../../components/quantitySelector/QuantitySelector';

const i18nData = require('./pageBasket.i18n');

export default {
  name: 'PageBasket',
  components: { QuantitySelector, FormAddress, Steppers },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      PUBLIC: 0,
      PRIVATE_WITH_BILLING: 1,
      PRIVATE_WITHOUT_BILLING: 2,
      alertPartErrorStatus: false,
      partsErrorsShared: false,
      loaded: false,
      loadingCreateQuote: false,
      inputSelectAllHeader: null,
      wizardItems: [],
      checkAllSelected: false,
      checkboxes: null,
      checkedToDelete: [],
      countTotal: 0,
      linkToLeave: '',
      basket: null,
      partsErrorStatus: [],
      sameAsBilling: false,
      dialogLeavePage: false,
      headerCheckboxSelectMultiple: false,
      headersTable: [],
      search: '',
      singleSelect: false,
      selectedItemsTable: [],
      step: 1,
      quantityChanged: false,
      basketSubmitted: false,
      quotesCreated: [],
      dialogMultipleQuotes: false,
      partsImageDataURL: {}
    };
  },
  created() {
    this.wizardItems = [
      this.$t('My Basket'),
      this.$t('deliveryAddress'),
      this.$t('quoteCreation'),
    ];
    this.getBasket();
  },
  mounted() {
    this.headersTable = [
      {
        text: '',
        value: 'part.image',
        sortable: false,
        show: true,
        width: '140px'
      },
      {
        text: this.$t('name'),
        value: 'name',
        sortable: false,
        show: true
      },
      {
        text: this.$t('internalReference'),
        value: 'internalReference',
        sortable: false,
        show: true
      },
      {
        text: this.$t('ManufacturingInformation'),
        value: 'part.manufacturingInformation',
        sortable: false,
        show: true
      },
      {
        text: this.$t('quantity'),
        value: 'quantity',
        align: 'center',
        sortable: false,
        show: true
      },
      {
        text: this.$t('VATExcludedTotalPrice'),
        value: 'VATExcludedTotalPrice',
        align: 'right',
        sortable: false,
        show: true
      },
      {
        value: 'data-table-select',
        sortable: false,
        align: 'center',
        show: true
      }
    ];
  },
  /**
   * DIALOG BEFORE LEAVING THE PAGE
   */
  beforeRouteLeave(to, from, next) {
    if (
      this.step === 3 &&
      this.linkToLeave === '' &&
      to.name !== 'Quote' &&
      to.name !== 'Administration' &&
      this.basket.items.length > 0 &&
      !this.basketSubmitted
    ) {
      this.dialogLeavePage = true;
      this.linkToLeave = `/#${to.path}`;
    } else {
      if (to.name !== 'Quote') {
        this.dialogLeavePage = false;
      }
      next();
    }
  },
  computed: {
    enableCategories() {
      return !!this.$appConfig.brand.features.privateSuppliers.length;
    },
    computedHeadersTable() {
      return this.headersTable.filter(h => {return h.show;});
    },
    computedItems() {
      if(this.enableCategories) {
        /**
         * We want to group items between 3 categories :
         * Public parts (manufactured by public suppliers)
         * Private parts WITH billing (manufactured by private suppliers)
         * Private parts WITHOUT billing (manufactured by private suppliers)
         */
        for(const item of this.basket.items) {

          if(item.part.homologation.public) {
            item._category = this.PUBLIC;
          } else {
            const privateSupplier = this.$appConfig.brand.features.privateSuppliers.find(pS => {
              return pS.supplier._id === item.part.homologation.supplier;
            });
            if(privateSupplier && !privateSupplier.billing) {
              item._category = this.PRIVATE_WITHOUT_BILLING;
            } else {
              item._category = this.PRIVATE_WITH_BILLING;
            }

          }

        }
      }
      return this.basket.items;
    },
    hasUnconfiguredParts() {
      return this.basket.items.some(i => {
        return i.part.status === 'NEW';
      });
    }
  },
  methods: {
    /**
     * LEAVE THE PAGE BASKET
     */
    leavePageBasket() {
      location.replace(this.linkToLeave);
    },
    getBasket() {
      /**
       * We return a Promise to be able to do actions after the basket is updated
       */
      return new Promise((resolve, reject) => {
        this.$apiInstance.getUserBrandBasket(
          this.$appConfig.user._id,
          this.$appConfig.brand._id
        ).then(data => {
          for(const item of data.items) {
            if(item.part) {
              this.$set(this.partsImageDataURL, item.part._id, null);
              this.getBrandPartImage(item.part);
            }
          }
          this.basket = data;
          this.verifyPartStatus();
          resolve();
        }).catch(err => {
          reject(err);
        });
      });
    },
    getBrandPartImage(part) {
      if(part.image) {
        const [promise, req] = DownloadBrandPartImage.downloadBrandPartImage(
          this.$apiInstance,
          this.$route.params.brandUUID, 
          part._id,
          new Date(part.image.created).getTime()
        );

        promise.then(data => {
          const png = new File([new Uint8Array(data.buffer)], 'part.png', {
            type: 'image/png'
          });
          this.partsImageDataURL[part._id] = URL.createObjectURL(png);
        });
      }
    },
    isBilledPart(part) {
      if(part.homologation.public) {
        return true;
      }
      const privateSupplier = this.$appConfig.brand.features.privateSuppliers.find(pS => { return part.homologation.supplier === pS.supplier._id;});
      if(!privateSupplier || privateSupplier.billing) {
        return true;
      }
      return false;
    },
    /**
     * Update basket with current basket items
     */
    modifyBasket(notify = false) {
      this.loaded = false;
      const items = this.basket.items.map(item => {
        return {
          part: item.part._id,
          quantity: item.quantity
        };
      });

      const modifyUserBrandBasketBody = new this.$BcmModel.ModifyUserBrandBasketBody(
        items,
        this.basket.deliveryAddress ? this.$BcmModel.BasketDeliveryAddress.constructFromObject(this.basket.deliveryAddress) : null
      );

      this.$apiInstance.modifyUserBrandBasket(
        this.$appConfig.user._id,
        this.$appConfig.currentBrand,
        modifyUserBrandBasketBody
      ).then(
        (data) => {
          this.basket = data;
          this.quantityChanged = false;
          this.selectedItemsTable = [];
          this.verifyPartStatus();
          if(notify) {
            this.$notification.notify('SUCCESS', this.$t('BasketHasBeenUpdated'));
          }
          this.$basket.getBasket();
        },
        (error) => {
          /**
           * UNABLE TO SAVE THE BASKET
           */
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        }
      );
    },
    verifyPartStatus() {
      /**
       * We should remove every part whose file has been modified since the last homologationStatusChange
       * or whose dna.technology / dna.material does not match part.homologation.technology / material
       */
      const prevCount = this.basket.items.length;
      const now = new Date();

      this.partsErrorsShared = this.basket.items.some(item => {
        return !item.part;
      });

      const partsErrorStatus = this.basket.items.filter(item => {
        return this.isItemWithIssue(item);
      }).map(item => {return item.part;});

      this.basket.items = this.basket.items.filter(item => {
        return !this.isItemWithIssue(item);  
      });

      if(this.partsErrorsShared || prevCount !== this.basket.items.length) {
        /**
         * At least one part is not orderable, we should update the basket items list
         */
        this.partsErrorStatus = this.partsErrorStatus.concat(partsErrorStatus);
        this.alertPartErrorStatus = true;
        this.modifyBasket();
      } else {
        this.loaded = true;
      }

    },
    isItemWithIssue(item) {
      if(!item.part) {
        return true;
      }

      if(!item.part.dna || !item.part.dna.completed || !item.part.homologation) {
        return true;
      }

      if(item.part.originalFile) {
        if(new Date(item.part.originalFile.created) > new Date(item.part.homologation.lastStatusUpdate)) {
          return true;
        }
      } else {
        if(!item.part.file) {
          return true;
        }
        if(new Date(item.part.file.created) > new Date(item.part.homologation.lastStatusUpdate)) {
          return true;
        }
      }

      if(item.part.dna.technology !== item.part.homologation.technology || item.part.dna.material !== item.part.homologation.material) {
        return true;
      }

      return false;
    },
    /**
     * UPDATE BASKET PART QUANTITY
     */
    updateBasketPartQuantity(item, quantity) {
      for (const basketItem of this.basket.items) {
        if(basketItem.part) {
          if (basketItem.part._id === item.part._id) {
            basketItem.quantity = quantity;
            break;
          }
        }
      }
      this.quantityChanged = true;
    },
    getVATExcludedItemUnitaryPrice(item) {
      let unitaryPrice = 0;
      for(const priceObj of item.part.homologation.prices) {
        if(item.quantity >= priceObj.quantity) {
          unitaryPrice = priceObj.sellingPrice;
        } else {
          break;
        }
      }
      return unitaryPrice;
    },
    getVATExcludedItemTotalPrice(item) {
      let unitaryPrice = 0;
      for(const priceObj of item.part.homologation.prices) {
        if(item.quantity >= priceObj.quantity) {
          unitaryPrice = priceObj.sellingPrice;
        } else {
          break;
        }
      }
      return Math.round(100 * unitaryPrice * item.quantity) / 100;
    },
    getVATExcludedBasketTotalPrice() {
      let total = 0;
      for(const item of this.basket.items) {
        if(item.part.status === 'NEW' || !this.isBilledPart(item.part)) {
          continue;
        }
        let unitaryPrice = 0;
        for(const priceObj of item.part.homologation.prices) {
          if(item.quantity >= priceObj.quantity) {
            unitaryPrice = priceObj.sellingPrice;
          } else {
            break;
          }
        }
        total += Math.round(100 * unitaryPrice * item.quantity) / 100;
      }
      return total;
    },
    headerCheckboxSelectMultipleChanged(value) {
      this.selectedItemsTable = [];
      if(value) {
        this.selectedItemsTable = [...this.basket.items];
      }
    },
    removeSelectedItems() {
      /**
       * Update basket items
       */
      this.basket.items = this.basket.items.filter(basketItem => {
        return !this.selectedItemsTable.some(selectedItem => {
          return selectedItem.part._id === basketItem.part._id;
        });
      });
      this.modifyBasket(true);
    },
    removeItem(item) {
      this.basket.items = this.basket.items.filter(basketItem => {
        return basketItem.part._id !== item.part._id;
      });
      this.modifyBasket(true);
    },
    /**
     * GO TO NEXT STEP
     */
    nextStep() {
      let errorStep2 = false;
      switch (this.step) {
      case 1:
        /**
           * Update Basket if quantity has changed and switch to step 2
           */
        if (!this.basket.deliveryAddress || Object.values(this.basket.deliveryAddress).join('') === '') {
          this.sameAsBilling = true;
          this.sameAsBillingClick();
        }

        this.modifyBasket();
        this.selectedItemsTable = [];
        this.step = 2;
        break;
      case 2:
        /**
           * Update if Address has changed and switch to step 3
           */
        errorStep2 = !this.$refs.formDelivery.validate();
        if (!errorStep2) {
          this.modifyBasket();
          this.step = 3;
        }
        break;
      case 3:
        /**
           * Create the Quote and go to created quote
           */
        break;
      }
      if(this.step === 1) {
        this.headersTable[5].show = true;
      } else {
        this.headersTable[5].show = false;
      }
    },
    /**
     * GO TO PREVIOUS STEP
     */
    previousStep() {
      this.sameAsBilling = !!ObjectCompare.compare(
        this.$BcmModel.BasketDeliveryAddress.constructFromObject(this.basket.deliveryAddress),
        this.$BcmModel.BasketDeliveryAddress.constructFromObject(this.$appConfig.brand.address)
      );
      this.step = Number.parseInt(this.step) - 1;
      if(this.step === 1) {
        this.headersTable[5].show = true;
      } else {
        this.headersTable[5].show = false;
      }
    },
    /**
     * USE SAME ADDRESS FOR BILLING AND DELIVERY
     */
    sameAsBillingClick() {
      const address = new this.$BcmModel.BasketDeliveryAddress();
      if (this.sameAsBilling) {
        //copy billing address to delivery address
        this.basket.deliveryAddress = this.$BcmModel.BasketDeliveryAddress.constructFromObject(this.$appConfig.brand.address);
      } else {
        //new address object to cleanup everything
        address.country = 'FR';
        address.company = '';
        address.streetAddress = '';
        address.zipCode = '';
        address.city = '';
        this.basket.deliveryAddress = address;
      }
    },
    /**
     * QUOTE CREATION
     */
    createQuote() {
      this.loadingCreateQuote = true;
      /**
       * SUBMIT USER BRAND BASKET
       */
      this.$apiInstance
        .submitUserBrandBasket(
          this.$appConfig.user._id,
          this.$appConfig.currentBrand
        )
        .then(
          (data) => {
            EventBus.$emit('globalEventBasketMenuUpdateBadge');
            this.basketSubmitted = true;
            if(data.length > 1) {
              this.$notification.notify('SUCCESS', this.$t('QuotesCreated'));
              this.quotesCreated = data;
              this.dialogMultipleQuotes = true;
            } else {
              this.$notification.notify('SUCCESS', this.$t('QuoteCreated'));
              this.$router.push({name: 'Quote', params: { brandUUID: `${this.$appConfig.currentBrand}`, quoteUUID: data[0]._id.toString()}});
            }
          },
          (error) => {
            /**
             * ERROR SUBMIT USER BRAND BASKET
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            this.getBasket();
          }
        )
        .finally(() => {
          this.loadingCreateQuote = false;
          this.$basket.getBasket();
        });
    },
    updateDeliveryAddress(newAddress) {
      this.basket.deliveryAddress = newAddress;
    }
  },
};
</script>
