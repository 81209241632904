var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    staticClass: "module-quantitySelector d-flex"
  }, [_c('div', {
    on: {
      "click": _vm.quantityDecrease
    }
  }, [_c('v-icon', {
    attrs: {
      "append-outer-icon": ""
    }
  }, [_vm._v(" fas fa-minus ")])], 1), _c('v-text-field', {
    attrs: {
      "type": "number",
      "solo": ""
    },
    on: {
      "change": _vm.quantityInputValidate
    },
    model: {
      value: _vm.quantityComputed,
      callback: function ($$v) {
        _vm.quantityComputed = _vm._n($$v);
      },
      expression: "quantityComputed"
    }
  }), _c('div', {
    on: {
      "click": _vm.quantityIncrease
    }
  }, [_c('v-icon', {
    attrs: {
      "append-outer-icon": ""
    }
  }, [_vm._v(" fas fa-plus ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }