var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-Basket"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-wrap display-1 pa-0 ma-3",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('My Basket')) + " ")]), _vm.basket && _vm.basket.items.length > 0 ? _c('v-col', {
    attrs: {
      "cols": "7",
      "justify": "center"
    }
  }, [_c('Steppers', {
    attrs: {
      "stepper": _vm.step,
      "steps": _vm.wizardItems
    }
  })], 1) : _vm._e(), _vm.basket && _vm.basket.items.length > 0 ? _c('v-col', {
    staticClass: "d-inline",
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none white--text mr-4",
    attrs: {
      "disabled": _vm.step === 1,
      "color": "orange"
    },
    on: {
      "click": _vm.previousStep
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-left ")]), _vm._v(" " + _vm._s(_vm.$t("Previous")) + " ")], 1), _vm.step < 3 && !_vm.quantityChanged ? _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange",
      "disabled": !_vm.$appConfig.brand.address.VATnumber && _vm.step === 2 || _vm.selectedItemsTable.length > 0
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")]), _vm._v(" " + _vm._s(_vm.$t("Next")) + " ")], 1) : _vm._e(), _vm.step === 1 && _vm.quantityChanged ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.modifyBasket(true);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('UpdateBasket')) + " ")])], 1) : _vm._e(), _vm.step === 3 ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingCreateQuote,
      "disabled": _vm.loadingCreateQuote,
      "color": "primary"
    },
    on: {
      "click": _vm.createQuote
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-signature ")]), _vm._v(" " + _vm._s(_vm.$t("CreateQuote")) + " ")], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.basket ? [_vm.basket && _vm.basket.items.length > 0 ? [_vm.step === 2 || _vm.step === 3 ? _c('div', {
    staticClass: "row white elevation-3 pa-4 px-12 rounded mb-8 mx-0",
    attrs: {
      "id": "basketAddress"
    }
  }, [_c('div', {
    staticClass: "col billingAddress"
  }, [_c('h4', {
    staticClass: "headline font-weight-bold mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('billingAddress')) + " ")]), _c('h6', {
    staticClass: "title mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$appConfig.brand.address.company) + " ")]), _c('p', [_vm._v(_vm._s(_vm.$appConfig.brand.address.firstname) + " " + _vm._s(_vm.$appConfig.brand.address.lastname))]), _c('p', [_vm._v(_vm._s(_vm.$appConfig.brand.address.streetAddress))]), _c('p', [_vm._v(_vm._s(_vm.$appConfig.brand.address.zipCode) + " " + _vm._s(_vm.$appConfig.brand.address.city))]), _c('p', [_vm._v(_vm._s(_vm.$t(`countries.${_vm.$appConfig.brand.address.country}`)))]), !_vm.$appConfig.brand.address.VATnumber ? _c('v-alert', {
    attrs: {
      "prominent": "",
      "type": "error",
      "text": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('VATNumberMissing')) + " ")]), _c('p', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'Administration'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-users-cog ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('ConfigureVAT')) + " ")])], 1)], 1)]) : _vm._e()], 1), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "text-md-right"
  }, [_vm.step === 2 ? [_c('div', {
    staticClass: "headline font-weight-bold mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('deliveryAddress')) + " ")]), _c('v-btn', {
    staticClass: "text-none mb-4",
    attrs: {
      "color": "info",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        _vm.sameAsBilling = !_vm.sameAsBilling;
        _vm.sameAsBillingClick();
      }
    }
  }, [!_vm.sameAsBilling ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" far fa-square ")]) : _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-check-square ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('useSameAsBillingAddress')) + " ")])], 1), _c('formAddress', {
    ref: "formDelivery",
    staticClass: "mt-4",
    attrs: {
      "address": _vm.basket.deliveryAddress,
      "restricted-country-list": this.$appConfig.brand.features.availableDeliveryCountries.concat(this.$appConfig.brand.address.country),
      "show-v-a-tnumber": false,
      "required-phone-number": true,
      "required-company": true,
      "required-first-name": true,
      "required-last-name": true,
      "required-zip-code": true,
      "required-v-a-tnumber": false,
      "is-valid-v-a-t": true
    },
    on: {
      "updated": _vm.updateDeliveryAddress
    }
  }), _c('v-btn', {
    staticClass: "text-none mt-2",
    attrs: {
      "color": "info",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        _vm.sameAsBilling = !_vm.sameAsBilling;
        _vm.sameAsBillingClick();
      }
    }
  }, [!_vm.sameAsBilling ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" far fa-square ")]) : _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-check-square ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('useSameAsBillingAddress')) + " ")])], 1)] : _vm._e(), _vm.step === 3 ? [_c('div', {
    staticClass: "col deliveryAddress"
  }, [_c('h4', {
    staticClass: "headline font-weight-bold mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('deliveryAddress')) + " ")]), _c('h6', {
    staticClass: "title mb-4"
  }, [_vm._v(" " + _vm._s(_vm.basket.deliveryAddress.company) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.basket.deliveryAddress.firstname) + " " + _vm._s(_vm.basket.deliveryAddress.lastname) + " ")]), _c('p', [_vm._v(_vm._s(_vm.basket.deliveryAddress.streetAddress))]), _c('p', [_vm._v(" " + _vm._s(_vm.basket.deliveryAddress.zipCode) + " " + _vm._s(_vm.basket.deliveryAddress.city) + " ")]), _c('p', [_vm._v(_vm._s(_vm.$t(`countries.${_vm.basket.deliveryAddress.country}`)))]), _c('p', [_vm._v(_vm._s(_vm.basket.deliveryAddress.phoneNumber))])])] : _vm._e()], 2)])]) : _vm._e(), _vm.step === 1 || _vm.step === 3 ? [_c('v-card', {
    attrs: {
      "disabled": !_vm.loaded
    }
  }, [_c('v-data-table', {
    attrs: {
      "show-select": _vm.step === 1,
      "item-key": "part._id",
      "headers": _vm.computedHeadersTable,
      "items": _vm.computedItems,
      "footer-props": {
        itemsPerPageOptions: [10, 50, 100, -1]
      },
      "loading": !_vm.loaded,
      "group-by": _vm.enableCategories ? '_category' : null
    },
    scopedSlots: _vm._u([{
      key: `header.data-table-select`,
      fn: function (_ref) {
        var props = _ref.props;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-checkbox', {
          attrs: {
            "ripple": false,
            "indeterminate": props.indeterminate
          },
          on: {
            "change": _vm.headerCheckboxSelectMultipleChanged
          },
          model: {
            value: props.value,
            callback: function ($$v) {
              _vm.$set(props, "value", $$v);
            },
            expression: "props.value"
          }
        }), _c('v-btn', {
          staticClass: "ml-2 text-none",
          attrs: {
            "outlined": "",
            "color": "error",
            "disabled": !props.value && !props.indeterminate
          },
          on: {
            "click": _vm.removeSelectedItems
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")]), _c('span', [_vm._v(" x" + _vm._s(_vm.selectedItemsTable.length) + " ")])], 1)], 1)];
      }
    }, {
      key: `group.header`,
      fn: function (_ref2) {
        var group = _ref2.group,
          headers = _ref2.headers,
          isOpen = _ref2.isOpen,
          toggle = _ref2.toggle;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "color": "info",
            "icon": ""
          },
          on: {
            "click": toggle
          }
        }, [isOpen ? _c('v-icon', [_vm._v(" fas fa-minus ")]) : _c('v-icon', [_vm._v(" fas fa-plus ")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [group === _vm.PUBLIC ? _c('v-card', {
          staticClass: "mr-2",
          attrs: {
            "width": "40px",
            "height": "36px",
            "color": "info"
          }
        }, [_c('v-card-text', {
          staticClass: "pa-1"
        }, [_c('v-img', {
          attrs: {
            "contain": "",
            "max-height": "30px",
            "src": require("../../assets/logo.png")
          }
        })], 1)], 1) : _c('v-card', {
          staticClass: "mr-2",
          attrs: {
            "width": "40px",
            "height": "36px",
            "color": "info"
          }
        }, [_c('v-card-text', {
          staticClass: "d-flex align-center justify-center fill-height"
        }, [_c('v-icon', {
          attrs: {
            "color": "privateSupplier",
            "size": "24px"
          }
        }, [_vm._v(" fas fa-industry ")])], 1)], 1)], 1), _c('v-col', {
          staticClass: "pl-0",
          attrs: {
            "cols": "auto",
            "align-self": "center"
          }
        }, [group === _vm.PUBLIC ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartsProducedByBCMSuppliers')) + " ")]) : group === _vm.PRIVATE_WITH_BILLING ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartsProducedInYourProductionSites')) + " (" + _vm._s(_vm.$t('WithBilling')) + ") ")]) : group === _vm.PRIVATE_WITHOUT_BILLING ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartsProducedInYourProductionSites')) + " (" + _vm._s(_vm.$t('WithoutBilling')) + ") ")]) : _vm._e()])], 1)], 1)];
      }
    }, {
      key: `item.part.image`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [!item.part || !item.part.image || !_vm.partsImageDataURL[item.part._id] ? _c('v-skeleton-loader', {
          staticClass: "ma-2",
          attrs: {
            "width": "128",
            "height": "128",
            "boilerplate": !item.part || !item.part.image,
            "type": "image"
          }
        }) : _vm.partsImageDataURL[item.part._id] ? _c('v-img', {
          staticClass: "ma-2",
          attrs: {
            "src": _vm.partsImageDataURL[item.part._id],
            "max-width": "128",
            "max-height": "128",
            "contain": ""
          }
        }) : _vm._e()];
      }
    }, {
      key: `item.name`,
      fn: function (_ref4) {
        var _item$part;
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Part',
              params: {
                brandUUID: `${_vm.$route.params.brandUUID}`,
                partUUID: `${(_item$part = item.part) === null || _item$part === void 0 ? void 0 : _item$part._id}`
              }
            }
          }
        }, [item.part ? _c('span', {
          staticClass: "text-decoration-none d-block text-wrap text-truncate nameColumn",
          staticStyle: {
            "max-width": "35vw !important"
          }
        }, [_vm._v(" " + _vm._s(item.part.name) + " ")]) : _vm._e()])];
      }
    }, {
      key: `item.internalReference`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.part ? _c('span', {
          staticClass: "text-decoration-none text-truncate d-block",
          staticStyle: {
            "max-width": "200px !important"
          }
        }, [_vm._v(" " + _vm._s(item.part.internalReference) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.part.manufacturingInformation`,
      fn: function (_ref6) {
        var _item$part2, _item$part2$dna, _item$part3, _item$part3$dna, _item$part4, _item$part4$homologat, _item$part4$homologat2, _item$part5, _item$part6;
        var item = _ref6.item;
        return [_c('div', [_c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('Technology')) + ": ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t((_item$part2 = item.part) === null || _item$part2 === void 0 ? void 0 : (_item$part2$dna = _item$part2.dna) === null || _item$part2$dna === void 0 ? void 0 : _item$part2$dna.technology)) + " ")])]), _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('Material')) + ": ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t((_item$part3 = item.part) === null || _item$part3 === void 0 ? void 0 : (_item$part3$dna = _item$part3.dna) === null || _item$part3$dna === void 0 ? void 0 : _item$part3$dna.material)) + " ")]), (_item$part4 = item.part) !== null && _item$part4 !== void 0 && (_item$part4$homologat = _item$part4.homologation) !== null && _item$part4$homologat !== void 0 && (_item$part4$homologat2 = _item$part4$homologat.manufacturingInformations) !== null && _item$part4$homologat2 !== void 0 && _item$part4$homologat2.material ? _c('span', [_vm._v(" (" + _vm._s(item.part.homologation.manufacturingInformations.material) + ") ")]) : _vm._e()]), (_item$part5 = item.part) !== null && _item$part5 !== void 0 && _item$part5.homologation.manufacturingInformations ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('Finishes')) + ": ")]), _c('span', [_vm._v(" " + _vm._s(((_item$part6 = item.part) === null || _item$part6 === void 0 ? void 0 : _item$part6.homologation.manufacturingInformations.finishes) || _vm.$t('None')) + " ")])]) : _vm._e()])];
      }
    }, {
      key: `item.quantity`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm.step === 1 ? _c('div', {
          staticClass: "quantitySelector"
        }, [_c('QuantitySelector', {
          attrs: {
            "quantity": item.quantity,
            "min": 1,
            "part": item.part
          },
          on: {
            "quantityUpdated": function ($event) {
              return _vm.updateBasketPartQuantity(item, $event);
            }
          }
        })], 1) : _vm._e(), _vm.step === 3 ? [_vm._v(" " + _vm._s(item.quantity) + " ")] : _vm._e()];
      }
    }, {
      key: `item.VATExcludedTotalPrice`,
      fn: function (_ref8) {
        var _item$part7, _item$part8, _item$part8$homologat;
        var item = _ref8.item;
        return [((_item$part7 = item.part) === null || _item$part7 === void 0 ? void 0 : _item$part7.status) === 'NEW' || !((_item$part8 = item.part) !== null && _item$part8 !== void 0 && (_item$part8$homologat = _item$part8.homologation) !== null && _item$part8$homologat !== void 0 && _item$part8$homologat.prices.length) ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('OnQuote')) + " ")]) : _vm.isBilledPart(item.part) ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.getVATExcludedItemTotalPrice(item), "currency")) + " ")]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v(" N/A (" + _vm._s(_vm.$t('WithoutBilling').toLowerCase()) + ") ")])];
      }
    }, {
      key: `item.data-table-select`,
      fn: function (_ref9) {
        var item = _ref9.item,
          on = _ref9.on,
          props = _ref9.props,
          isSelected = _ref9.isSelected,
          select = _ref9.select;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-checkbox', _vm._g(_vm._b({
          staticClass: "mt-0 pt-0 checkbox-delete",
          attrs: {
            "ripple": false,
            "value": isSelected,
            "hide-detail": "",
            "hide-spin-buttons": "",
            "content-class": "my-0"
          },
          on: {
            "change": function ($event) {
              return select($event);
            }
          }
        }, 'v-checkbox', props, false), on)), _c('v-btn', {
          staticClass: "ml-2 text-none",
          attrs: {
            "outlined": "",
            "icon": "",
            "color": "error",
            "disabled": !!_vm.selectedItemsTable.length
          },
          on: {
            "click": function ($event) {
              return _vm.removeItem(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")])], 1)], 1)];
      }
    }, {
      key: `body.append`,
      fn: function () {
        return [_c('tr', [_c('td', {
          attrs: {
            "colspan": 4
          }
        }), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": 1
          }
        }, [_vm.step === 1 ? _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "disabled": !_vm.quantityChanged,
            "color": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.modifyBasket(true);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('UpdateBasket')) + " ")])], 1) : _vm._e()], 1), _c('td', {
          staticClass: "text-right",
          attrs: {
            "colspan": 1
          }
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('BasketTotal')) + " (" + _vm._s(_vm.$t('VATExcluded')) + ") ")]), _vm.hasUnconfiguredParts ? _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v(" (" + _vm._s(_vm.$t('ExcludingPartsOnQuote')) + ") ")]) : _vm._e(), _c('span', [_vm._v(" : ")]), _c('span', {
          staticClass: "font-weight-medium text-subtitle-1"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.getVATExcludedBasketTotalPrice(), 'currency')) + " ")])]), _vm.step === 1 ? _c('td', {
          attrs: {
            "colspan": 1
          }
        }) : _vm._e()])];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selectedItemsTable,
      callback: function ($$v) {
        _vm.selectedItemsTable = $$v;
      },
      expression: "selectedItemsTable"
    }
  })], 1)] : _vm._e(), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('div', {
    staticClass: "mt-4 mb-12 d-flex justify-end"
  }, [_c('v-btn', {
    staticClass: "text-none white--text mr-4",
    attrs: {
      "disabled": _vm.step === 1,
      "color": "orange"
    },
    on: {
      "click": _vm.previousStep
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-left ")]), _vm._v(" " + _vm._s(_vm.$t("Previous")) + " ")], 1), _vm.step < 3 && !_vm.quantityChanged ? _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange",
      "disabled": !_vm.$appConfig.brand.address.VATnumber && _vm.step === 2 || _vm.selectedItemsTable.length > 0
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-right ")]), _vm._v(" " + _vm._s(_vm.$t("Next")) + " ")], 1) : _vm._e(), _vm.step === 1 && _vm.quantityChanged ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.modifyBasket(true);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('UpdateBasket')) + " ")])], 1) : _vm._e(), _vm.step === 3 ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingCreateQuote,
      "disabled": _vm.loadingCreateQuote,
      "color": "primary"
    },
    on: {
      "click": _vm.createQuote
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-signature ")]), _vm._v(" " + _vm._s(_vm.$t("CreateQuote")) + " ")], 1) : _vm._e()], 1)] : _c('div', {
    staticClass: "text-center",
    attrs: {
      "id": "basket-empty"
    }
  }, [_c('h2', {
    staticClass: "display-1 font-weight-light mt-10"
  }, [_vm._v(" " + _vm._s(_vm.$t('BasketEmpty')) + " ")]), _c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" fas fa-shopping-cart ")]), _c('h5', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('BasketEmptyHelp')) + " ")])], 1)] : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "primary-title": "",
      "persistent": ""
    },
    model: {
      value: _vm.dialogLeavePage,
      callback: function ($$v) {
        _vm.dialogLeavePage = $$v;
      },
      expression: "dialogLeavePage"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "info",
      "large": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t("beforeLeaveTitle")) + " ")], 1), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', {
    staticClass: "d-md-flex justify-space-around"
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('beforeLeaveText')) + " ")])], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "info text-none",
    on: {
      "click": function ($event) {
        _vm.linkToLeave = '';
        _vm.dialogLeavePage = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.leavePageBasket();
        _vm.dialogLeavePage = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('Leave')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1000",
      "persistent": ""
    },
    model: {
      value: _vm.dialogMultipleQuotes,
      callback: function ($$v) {
        _vm.dialogMultipleQuotes = $$v;
      },
      expression: "dialogMultipleQuotes"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('MultipleQuotesCreatedTitle')) + " ")]), _c('v-card-text', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('MultipleQuotesCreatedText')) + " ")]), _c('v-list', {
    staticClass: "mb-4"
  }, _vm._l(_vm.quotesCreated, function (quote, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "small": "",
        "color": "info"
      }
    }, [_vm._v(" fas fa-file-signature ")])], 1), _c('v-list-item-content', [_c('div', [_c('router-link', {
      attrs: {
        "to": {
          name: 'Quote',
          params: {
            brandUUID: _vm.$route.params.brandUUID,
            quoteUUID: `${quote._id}`
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Quote')) + " n° " + _vm._s(quote.quoteNumber) + " ")]), quote.selling && (quote.public || _vm.$appConfig.brand.features.privateSuppliers.some(function (pS) {
      return pS.supplier._id === quote.dispatch[0].supplier;
    })) ? _c('span', [_vm._v(" (" + _vm._s(_vm.$n(quote.selling.totalAmount, 'currency', _vm.$userLocale)) + " " + _vm._s(_vm.$t('VATExcluded')) + ") ")]) : _vm._e()], 1)])], 1);
  }), 1), _c('p', [_c('router-link', {
    attrs: {
      "to": {
        name: 'Quotes',
        params: {
          brandUUID: _vm.$route.params.brandUUID
        }
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('GoToQuotesPage')) + " ")]), _c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-external-link-alt ")])], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.alertPartErrorStatus,
      callback: function ($$v) {
        _vm.alertPartErrorStatus = $$v;
      },
      expression: "alertPartErrorStatus"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('BasketHasBeenModified')) + " ")]), _c('v-card-text', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('PartsHaveBeenRemoved')) + " ")]), _vm.partsErrorStatus.length ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('PartsRemovedAre')) + " ")]) : _vm._e(), _c('ul', _vm._l(_vm.partsErrorStatus, function (part, index) {
    return _c('li', {
      key: index
    }, [_c(part ? 'router-link' : 'span', {
      tag: "v-router-link",
      attrs: {
        "to": {
          name: 'Part',
          params: {
            partUUID: part === null || part === void 0 ? void 0 : part._id,
            brandUUID: _vm.$route.params.brandUUID
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(part === null || part === void 0 ? void 0 : part.name) + " ")])], 1);
  }), 0)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.alertPartErrorStatus = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)], 1)], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }